var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"program-card",class:_vm.programCardClasses,style:(_vm.totalHeight)},[_c('div',{staticClass:"left-border",style:({ 'background-color': _vm.cardColor })}),_c('div',{staticClass:"program-card-wrapper"},[_c('div',{staticClass:"program-card-content"},[(_vm.isFavorite)?_c('div',{staticClass:"cur-p favorite",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleFavorite($event)}}},[_c('iconFavorite',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('button.rm_favorites'),
              classes: ['vue-tooltip-dark'],
              placement: 'bottom',
              delay: 0,
            }),expression:"{\n              content: $t('button.rm_favorites'),\n              classes: ['vue-tooltip-dark'],\n              placement: 'bottom',\n              delay: 0,\n            }"}]})],1):_c('div',{staticClass:"cur-p favorite",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleFavorite($event)}}},[_c('iconUnFavorite',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('button.favorites'),
              classes: ['vue-tooltip-dark'],
              placement: 'bottom',
              delay: 0,
            }),expression:"{\n              content: $t('button.favorites'),\n              classes: ['vue-tooltip-dark'],\n              placement: 'bottom',\n              delay: 0,\n            }"}]})],1),_c('div',{staticClass:"head"},[_c('div',{staticClass:"date-time"},[_vm._v(_vm._s(_vm.formattedTime))]),_c('div',{staticClass:"room-title"},[_vm._v(_vm._s(_vm.programSlot.conferenceRoomTitle))])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title webkit-clamped-box",style:({'-webkit-line-clamp': _vm.titleLineClamp})},[_vm._v(_vm._s(_vm.programTitle))]),(_vm.isProgramDescriptionVisible)?_c('div',{staticClass:"description webkit-clamped-box",style:({'-webkit-line-clamp': _vm.descriptionLineClamp})},[_vm._v(_vm._s(_vm.programDescription))]):_vm._e()]),(_vm.programSpeakers && _vm.programSpeakers.length)?_c('div',{staticClass:"speaker-list",class:{
          'speaker-list-shortened': _vm.program.speakers.length > _vm.programSpeakersDisplayLength,
        }},[_vm._l((_vm.programSpeakers),function(speaker,key){return [(key < _vm.programSpeakersDisplayLength)?_c('Speaker',{key:'speaker_' + speaker.id,attrs:{"contact":speaker,"speaker-type":"speaker","disable-online-badge":""}}):_vm._e()]}),(_vm.program.speakers.length > _vm.programSpeakersDisplayLength)?_c('div',{staticClass:"more",domProps:{"innerHTML":_vm._s(_vm.moreSpeakersHtml)}}):_vm._e()],2):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }